import React, { useEffect, useState } from "react";
import "./studentgrade.css";
import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SIS_STORAGE, STUDENT_GRADE } from "../../../apis/apis";
import { SearchOutlined } from "@ant-design/icons";
import SemesterGradeList from "./SemesterGradeList";

function Studentgrade() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [student_grades, set_student_grades] = useState("");
  const [response_table, set_response_table] = useState("");
  const [total_credits, set_total_credits] = useState("");
  const [total_earn_credits, set_total_earn_credits] = useState("");
  const [remaining_credits, set_remaining_credits] = useState("");
  const [loader, set_loader] = useState(true);

  const dataSource = [
    {
      key: "1",
      courseName: "Course name",
      courseCode: "BA 04",
      credit: 3.2,
      acredit: 3.3,
      grade: "CR",
    },
    {
      key: "2",
      courseName: "Course name",
      courseCode: "BA 04",
      credit: 3.1,
      acredit: 3.4,
      grade: "CR",
    },
    {
      key: "3",
      courseName: "Course name",
      courseCode: "BA 04",
      credit: 4,
      acredit: 3.5,
      grade: "CR",
    },
    {
      key: "4",
      courseName: "Course name",
      courseCode: "BA 04",
      credit: 2.1,
      acredit: 3.6,
      grade: "CR",
    },
    {
      key: "5",
      courseName: "Course name",
      courseCode: "BA 04",
      credit: 3.6,
      acredit: 3.7,
      grade: "CR",
    },
    { key: "5", courseCode: "Total", credit: 16.0, acredit: 15.0 },
  ];

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);

    const STUDENTGRADE_API_RESPONSE = await STUDENT_GRADE(FORM_DATA);
    console.log(STUDENTGRADE_API_RESPONSE);

    if (STUDENTGRADE_API_RESPONSE?.data?.status) {
      // console.log(STUDENTGRADE_API_RESPONSE)
      set_student_grades(STUDENTGRADE_API_RESPONSE?.data?.student_grades);
      set_response_table(STUDENTGRADE_API_RESPONSE?.data?.response_table);
      set_loader(false);
    }
  };

  useEffect(() => {
    console.log(
      SIS_STORAGE + "/enrollments/" + student_grades?.enrollment_image
    );
    VIEW_API();
  }, [loader]);

  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      width: "50%",
    },
    {
      title: "Course Code",
      dataIndex: "courseCode",
      key: "courseCode",
      width: "20%",
    },
    {
      title: " Attempted Credit",
      dataIndex: "credit",
      key: "credit",
      width: "20%",
    },
    {
      title: " Earn Credits",
      dataIndex: "credit",
      key: "credit",
      width: "10%",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      width: "10%",
    },
  ];

  return (
    <>
      <div className="sis-tab-menu">
        <span onClick={() => navigate("/student-detail/" + id)}>Profile</span>
        <span onClick={() => navigate("/student-academy/" + id)}>Academic</span>
        <span
          className="active"
          onClick={() => navigate("/student-grades/" + id)}
        >
          Grades
        </span>
      </div>

      <div className="Academic_section">
        <div>
          <img
            className="img_P"
            src={
              SIS_STORAGE + "/enrollments/" + student_grades?.enrollment_image
            }
          />
        </div>
        <div className="ishant">
          <span className="name_hed">
            {student_grades.enrollment_first_name}{" "}
            {student_grades.enrollment_middle_name != "null" &&
              student_grades.enrollment_middle_name &&
              student_grades.enrollment_middle_name}{" "}
            {student_grades.enrollment_last_name}
          </span>
          <span>Application number</span>
          <span>Studdent ID</span>
        </div>
        <div className="ishnat-dev">
          <span style={{ fontWeight: "500", color: "rgba(75, 75, 75, 1)" }}>
            {student_grades?.enrollment_application_no}{" "}
          </span>
          <span style={{ fontWeight: "500", color: "rgba(75, 75, 75, 1)" }}>
            {student_grades?.enrollment_student_id}
          </span>
        </div>
      </div>
      <br></br>
      <hr className="Line" />

      {/* 2nd part */}

      <div className="student_type_info">
        <div className="student_type_inf">
          <span>Student Status</span>
          <span>Programme / degree</span>
          <span className="applic-da">Matriculation Date</span>
          <span className="applic-da"> Matriculation Semester</span>
          <span>Confer Date</span>
        </div>
        <div className="student_type_inf1">
          <span>{student_grades.enrollment_student_status}</span>
          <span>{student_grades.programme_title}</span>
          <span>{student_grades.matriculation_date}</span>
          <span className="sem-date">
            {" "}
            {student_grades.matriculation_semester}
          </span>
          <span className="sem-date-wr">{student_grades.confer_date}</span>
        </div>
      </div>
      <hr className="Line" />

      <SemesterGradeList response_table={response_table} />
    </>
  );
}

export default Studentgrade;

import React from 'react'
import "./attendence.css"
import { Table, Input, Avatar } from 'antd';

function Attendencesetup() {
  const dataSource = [
    {
      key: '1',
      name: 'Present',
      age: 'Active',
      address: 'Allowed',
      type: 'present',
      excused: 'N/A',
      calculation: 'Yes',
      dayType: 'N/A',
      action: 'Edit/Delete',
    }
  ];
  const second_data = [
    {
      key: '2',
      name: 'Medical Appointment',
      age: 'Active',
      address: 'Allowed',
      type: 'Absence',
      excused: 'Excused',
      calculation: 'Yes',
      dayType: 'Full Day',
      action: 'Edit/Delete',
    }
  ];
  const new_data = {
    key: '3',
    name: 'Another Reason',
    age: 'Inactive',
    address: 'Not Allowed',
    type: 'Leave',
    excused: 'Unexcused',
    calculation: 'No',
    dayType: 'Half Day',
    action: 'Edit/Delete',
  };

  second_data.push(new_data);

  const columns = [
    {
      title: 'Reason',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'age',
      key: 'age',
      align: 'center',

    },
    {
      title: 'Faculity Access',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: 'Excused/Unexcused',
      dataIndex: 'excused',
      key: 'excused',
      align: 'center',
    },
    {
      title: 'Use in Calculation',
      dataIndex: 'calculation',
      key: 'calculation',
      align: 'center',
    },
    {
      title: 'Full Day/Half Day',
      dataIndex: 'dayType',
      key: 'dayType',
      align: 'center',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    },
  ];

  return (
    <div className=' full-Main'>
      <div>  <button className='Btn_Btn'>Add-catagory</button> </div><br></br>
      <div className='mid_main'>
        <div className='First_table'>
          <span className='first_heading'>Attended class</span><br></br>
          <br></br>
          <div className='first-table-dta'>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
          </div>
        </div><br></br>
        <br></br>

        <div className='First_table'>
          <span className='first_heading'>Absent Unexcused</span><br></br>
          <br></br>
          <div className='first-table-dta'>
            <Table columns={columns} pagination={false} dataSource={[...dataSource, ...second_data]} />
          </div>
        </div><br></br>
        <br></br>


        <div className='First_table'>
          <span className='first_heading'>Absent Excused</span><br></br>
          <br></br>
          <div className='first-table-dta'>
            <Table columns={columns} pagination={false} dataSource={[...dataSource, ...second_data]} />
          </div>
        </div><br></br>
        <br></br>

        <div className='First_table'>
          <span className='first_heading'>Tardy Excused</span><br></br>
          <br></br>
          <div className='first-table-dta'>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
          </div>
        </div><br></br>
        <br></br>
      </div>




    </div>
  )
}

export default Attendencesetup
import React, { useEffect, useState } from 'react';
import { UpOutlined, DownOutlined, SmileOutlined, InfoCircleFilled, CaretLeftFilled, CaretRightFilled, } from '@ant-design/icons';
import "./facultyattendance.css"
import { Input, Checkbox, Table, Select, Button, notification, Popconfirm } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import FacultyRecordAttendanceViewRow from './facultyRecordAttendanceViewRow';
import SisLoader from '../../widgets/loader';
import { ATTENDANCE_CANCEL, ATTENDANCE_LIST, sisDateFormat } from '../../apis/apis';

function FacultyRecordAttandanceView() {

    const [loader, set_loader] = useState(true);
    const [table_list, set_table_list] = useState([]);
    const { faculty_id, course_id, course_code, course_title, semester_id, timing, start_time, end_time, faculty_name, honorific, selected_date } = useParams();

    const [view_class_checkbox, set_view_class_checkbox] = useState(false);
    const [api_class_checkbox, set_api_class_checkbox] = useState(1);
    const [view_faculty_id, set_view_faculty_id] = useState('');
    const [view_course_id, set_view_course_id] = useState('');
    const [view_course_code, set_view_course_code] = useState('');
    const [view_course_title, set_view_course_title] = useState('');
    const [view_semester_id, set_view_semester_id] = useState('');
    const [view_timing, set_view_timing] = useState('');
    const [view_faculty_name, set_view_faculty_name] = useState('');
    const [view_honorific, set_view_honorific] = useState('');
    const [view_selected_date, set_view_selected_date] = useState('');


    const [sortedData, setSortedData] = useState([]);
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const handleSort = () => {
        const sorted = [...table_list].sort((a, b) => {
            const nameA = a.studentName.toLowerCase();
            const nameB = b.studentName.toLowerCase();
            if (sortDirection === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
        setSortedData(sorted);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = table_list.filter(item =>
            item.full_name.toString().toLowerCase().includes(query.toString().toLowerCase())
        );
        if (query === '') {
            setFilteredData(table_list);
        } else {
            setFilteredData(filtered);
        }
    };

    const ATTENDANCE_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("semester_id", atob(semester_id));
        FORM_DATA.append("course_id", atob(course_id));
        FORM_DATA.append("faculty_id", atob(faculty_id));
        FORM_DATA.append("selected_date", atob(selected_date));
        FORM_DATA.append("token", USER_DATA.token);
        const RESPONSE = await ATTENDANCE_LIST(FORM_DATA);
        console.log("Line Number.70", RESPONSE);
        if (RESPONSE?.data?.status) {
            set_api_class_checkbox(RESPONSE?.data?.edit_access)
            set_table_list(RESPONSE?.data?.students);
            setFilteredData(RESPONSE.data.students);
            set_loader(false)

        } else {
            set_loader(false)

        }
    }
    const navigate = useNavigate();
    useEffect(() => {
        set_view_faculty_id(atob(faculty_id))
        set_view_course_id(atob(course_id))
        set_view_course_code(atob(course_code))
        set_view_course_title(atob(course_title))
        set_view_semester_id(atob(semester_id))
        set_view_timing(atob(timing))
        set_view_faculty_name(atob(faculty_name))
        set_view_honorific(atob(honorific))
        set_view_selected_date(atob(selected_date))
        ATTENDANCE_LIST_API()
    }, [loader])

    const CANCEL_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("selected_date", atob(selected_date));
        FORM_DATA.append("course_id", view_course_id);
        FORM_DATA.append("semester_id", view_semester_id);
        FORM_DATA.append("start_time", atob(start_time));
        FORM_DATA.append("end_time", atob(end_time));
        FORM_DATA.append("faculty_id", view_faculty_id);
        const API_RESPONSE = await ATTENDANCE_CANCEL(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: "Success!!",
                description: "Attendance saved Successfully.",
                icon: <SmileOutlined style={{ color: "green" }} />,
            });
            set_view_class_checkbox(true)
        } else {
            notification.open({
                message: "Failed!!",
                description: API_RESPONSE?.data?.message,
                icon: <SmileOutlined style={{ color: "red" }} />,
            });

        }
    };

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <span className="blue-title" style={{ marginRight: "10px" }}>{view_course_code}</span>
                    <span className="blue-title">{view_course_title}</span>
                </div>
                <div className="col-5"><span className="black-title">Date:-  {sisDateFormat(view_selected_date)}, </span><span>{" "}</span><span className="black-title">{view_timing}</span></div>
                <div className="col-3"><span className="blue-title">{view_honorific} {view_faculty_name}</span></div>
            </div>
            <div>
                <hr />
                <div >
                    {view_class_checkbox ? <>

                        <Checkbox checked disabled={api_class_checkbox == 0 ? true : view_class_checkbox}>Class cancelled {api_class_checkbox}</Checkbox>
                    </> : <>

                        {api_class_checkbox == 0 ? <>
                            <Checkbox disabled={true}>Class cancelled</Checkbox></> : <>
                            <span style={{ cursor: "pointer" }}>

                                <Popconfirm
                                    title="Student Attendance"
                                    description="Are you sure you want to cancel this class?"
                                    onConfirm={CANCEL_API}
                                    okText="Yes"
                                    cancelText="No"

                                >
                                    <span className="custom-check-box"></span>Class cancelled
                                </Popconfirm>
                            </span>


                        </>}

                    </>}


                    <br />
                    <div className="Lu_sis_">
                        <div className='theme-content-head' style={{ marginBottom: '0px' }}>
                            <div className='theme-content-left-head'>
                                <span style={{ paddingRight: "10px" }}> <InfoCircleFilled style={{ color: "#0000FF", fontSize: "18px", fontWeight: "bold" }} /></span><span><b> if a class set as cancelled</b></span>
                                <p style={{ marginLeft: "27px" }}>All The Student Will be Marked as present</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="theme-content-head">
                            <h3 style={{ padding: "10px" }}>Search Table</h3>
                            <div className="input-box">
                                <Input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    {loader ? <SisLoader /> : <>
                        <div className="row reason-head">
                            <div className="col-3">Student Name 
                            {/* <span className="attendance-student-sort">
                                <CaretLeftFilled style={{ color: "#555", cursor: "default" }} onClick={handleSort} />
                                <CaretRightFilled style={{ color: "#555", cursor: "default" }} onClick={handleSort} /></span> */}
                                </div>
                            <div className="col-3">Mark Attendance</div>
                            <div className="col-2">Reason</div>
                            <div className="col-2">Comment</div>
                            <div className="col-1">Action</div>
                        </div>
                        {filteredData.length > 0 ? <>
                            {filteredData?.map((item) => (
                                <>
                                    <FacultyRecordAttendanceViewRow set_view_class_checkbox={set_view_class_checkbox} student={item} semester_id={view_semester_id} course_id={view_course_id} faculty_id={view_faculty_id} start_time={atob(start_time)} end_time={atob(end_time)} selected_date={view_selected_date} student_id={item?.id} />
                                </>
                            ))}
                        </> : <p style={{ color: "red", fontSize: "16px", textAlign: "center" }}>Empty Data</p>}

                    </>}
                    {/* Table Row Start Here */}

                    <br />
                    <Button
                        onClick={() => {
                            navigate('/record-attendance-class')

                        }}
                        type='primary'>Cancel </Button>
                </div>
            </div>
        </>
    );

}

export default FacultyRecordAttandanceView;

import axios from 'axios';
//export const BACKEND_URL = 'http://localhost/lu-sis';
//export const BACKEND_URL = 'https://uat.icore.uk';
export const BACKEND_URL = 'https://luserv.lincolnuca.edu';
//onst URL = 'http://localhost/lu-sis/api/';
//const URL = 'https://uat.icore.uk/api/';
const URL = 'https://luserv.lincolnuca.edu/api/';
const adminAxios = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
       ' Access-Control-Allow-Origin': '*'
    },
});

export default adminAxios;
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Spin } from 'antd';
import './login.css';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { CHECK_TOKEN, LOGIN_API } from '../apis/apis';
import SisLoader from '../widgets/loader';
import { useNavigate, useParams } from 'react-router-dom';
const Login = () => {
const { token } = useParams();
const navigate = useNavigate();
  const [login_check, set_login_check] = useState(false);
  const [loader, set_loader] = useState(true);
  const CHECK_TOKEN_API = async () => {
    try {
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', token);
        FORM_DATA.append('user_type', 'faculty');
        const CHECK_TOKEN_RESPONSE = await CHECK_TOKEN(FORM_DATA);
        if (CHECK_TOKEN_RESPONSE?.data?.status) {
          localStorage.setItem('sis_user_data', JSON.stringify(CHECK_TOKEN_RESPONSE.data.data));
          set_login_check(true);
          set_loader(false);
          navigate('/');
        } else {
          localStorage.clear();
          set_login_check(false);
          set_loader(false);
          navigate('/');
        };
      
   
    } catch (error) {
      localStorage.clear();
      set_login_check(false);
      set_loader(false);
    }
  }
  useEffect(() => {
    CHECK_TOKEN_API();
  }, []);
    return (
        <div className='login-box'>
              <SisLoader />
        </div>
    );
};

export default Login;
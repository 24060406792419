import { CommentOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { ADD_COMMENT, LIST_COMMENT } from "../../apis/apis";

export default function CommentList(props) {
  const { student_id, course_id, semester_id } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, set_loader] = useState(false);
  const [comment, set_comment] = useState("");
  const [errors, set_errors] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("semester_id", semester_id);
    const API_RESPONSE = await LIST_COMMENT(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.Student_Grades_Comments);
    } else {
    }
  };
  useEffect(() => {
    LIST_API();
  }, [loader]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("user_type", "admin");
    FORM_DATA.append("comment", comment);
    const API_RESPONSE = await ADD_COMMENT(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Commit Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_comment("");
      set_errors([]);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
     {table_list?.length > 0 ?
      <CommentOutlined onClick={showModal} style={{ cursor: "pointer", color:"#1677ff", fontSize:"20px" }} />
      : <CommentOutlined onClick={showModal} style={{ cursor: "pointer", color:"#ccc", fontSize:"20px"  }} /> }
      <Modal
        title="Comments"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          {table_list?.length > 0 ? (
            <>
              {" "}
              {table_list?.map((item) => (
                <>
                  <div className="commit_list">
                    <h6>
                      <span>{item.user_name}</span>
                      <span style={{ float:"right" }}>{item.grade_comment_updated_at}</span>
                    </h6>
                    <p>
                    {item.grade_comment_comment}
                    </p>
                  </div>
                </>
              ))}
               <hr />
            </>
          ) : (
            <>No Commit</>
          )}
        </div>
       
        <div>
          <label style={{ fontSize:"14px", fontWeight:"bold" }}>Add Commit</label>
          <TextArea
            value={comment}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={(e) => set_comment(e.target.value)}
          />
          {errors?.comment && (
            <>
              <span style={{ color: "red" }}>{errors?.comment[0]}</span>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

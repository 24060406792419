import React, { useEffect, useState } from "react";
import "./grading.css";
import { useNavigate, useParams } from "react-router-dom";
import { FilterFilled, SearchOutlined, SmileOutlined } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { ACADEMIC_SEMESTER, SEMESTER_WITH_REGISTERED_COURSE, STUDENTS_LISTING_BY_SEMESTER_AND_COURSE, UPDATE_GRADE } from "../../apis/apis";
import { Button, Input, Modal, Select, Table, notification } from "antd";
import { render } from "@testing-library/react";
import CommentList from "./CommentList";
// import grading from "./Gradingobject"

function GradingCourses() {
  const navigate = useNavigate();
  const { semester_id, course_id } = useParams();
  const [page_loader, set_page_loader] = useState(false);
  const [semester_details, set_semester_details] = useState([]);
  const [courses_list, set_courses_list] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [students_list, set_students_list] = useState([]);


  const [timing_json, set_timing_json] = useState([]);

  const STUDENTS_LISTING_BY_SEMESTER_AND_COURSE_list = async () => {
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', atob(semester_id));
    FORM_DATA.append('course_id', atob(course_id));
    const API_RESPONSE = await STUDENTS_LISTING_BY_SEMESTER_AND_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_students_list(API_RESPONSE.data.students);
      setFilteredData(API_RESPONSE.data.students);
      set_semester_details(API_RESPONSE.data.course_semester_registration);
      set_timing_json(JSON.parse(API_RESPONSE?.data?.course_semester_registration?.course_semester_registration_timing))
      set_page_loader(false);
    } else {
      set_page_loader(false);
    }
  };

  
  useEffect(() => {
    STUDENTS_LISTING_BY_SEMESTER_AND_COURSE_list();
  }, [page_loader]);

  const get_timing = () => {
    return (
      <>
        {timing_json.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                      style={{
                        width: "140px",
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        margin:"3px 0px"
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };
  const GradeOption = [
    {
      label:"A",
      value: "A",
    },
    {
      label:"B+",
      value: "B+",
    },
    {
      label:"B",
      value: "B",
    },
    {
      label:"B-",
      value: "B-",
    },
    {
      label:"C+",
      value: "C+",
    },
    {
      label:"C",
      value: "C",
    },
    {
      label:"C-",
      value: "C-",
    },
    {
      label:"D+",
      value: "D+",
    },
    {
      label:"D",
      value: "D",
    },
    {
      label:"F",
      value: "F",
    },
  ]
  
  const UPDATE_GRADE_API = async(grade, student_id) =>{
    set_page_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("course_id", atob(course_id));
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("semester_id", atob(semester_id));
    FORM_DATA.append("grade", grade);
    const API_RESPONSE = await UPDATE_GRADE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Garde Successfully updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
    
    } else {
      
    }
  }

  const columns = [
    {
      title: "Name",
      className:"minWidthColumn",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record.first_name} {record.middle_name != 'null' && record.middle_name  && record.middle_name } {record.last_name}</span></>;
      },
    },
    {
      title: "Grade",
      render: (text, record) => {
        return <>
          <Select style={{ width:"80px" }} value={record?.students_grade} onChange={value => UPDATE_GRADE_API(value, record?.id)} options={GradeOption} placeholder="Grade" />
        {/* <span style={{ fontSize:"10px" }}>{record?.students_grade}</span> */}
        </>;
      },
    },
    {
      title: "Comments",
      render: (text, record) => {
        return<>
            <CommentList student_id={record?.id} semester_id={semester_details?.course_semester_registration_semester_id} course_id={semester_details?.course_semester_registration_course_id} />
        </>
      }
    }
 
  ];
  //  Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = students_list;
    const filtered = table_data.filter(item =>
        item.first_name.toLowerCase().includes(query.toLowerCase())
       
    );
    if(query === ''){
        setFilteredData(students_list);
    }else{
        setFilteredData(filtered);
    }
};
  return (
    <>
      <div>
        <div className="grading-course-head-row">
        <div><Button type="primary" size="small"  onClick={() => navigate('/grading/'+ semester_id)}>Back</Button></div>
        <div>
            <p>Grading period</p>
            <h3>{semester_details?.academic_semesters_title}</h3>
        </div>
        <div>
        {/* <p>Grading period</p> */}
            <h3>{semester_details?.courses_code}</h3>
            <h3>{semester_details?.courses_title}</h3>
        </div>
        <div>
           {get_timing()}
        </div>
        <div>
           {semester_details?.faculties_name}
        </div>
        </div>
       
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
          <hr  />
               <div className='theme-content-head'>
                <div className='input-box'>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                   
                </div>
            </div>
            <div className="sis-tab-content">
            <Table dataSource={filteredData} columns={columns} />;
            </div> 
          </>
        )}
      </div>
    </>
  );
}

export default GradingCourses;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Spin, notification, DatePicker } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_FACULTIES } from '../../apis/apis';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const AddFaculty = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [disableButton, setDisableButton] = useState(true);

    const [name, set_name] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    // const [password, set_password] = useState('');
    // const [password_confirmation, set_password_confirmation] = useState('');
    const [address, set_address] = useState('');
    const [image, set_image] = useState('');
    const [department, set_academic_division] = useState('');
    const [designation, set_designation] = useState([]);
    const [second_designation, set_second_designation] = useState('');
    const [joiningDate, set_joiningDate] = useState('');
    const [salary, set_salary] = useState('');

    useEffect(() => {
        if (name && address && image && department && designation && joiningDate && salary) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [name, address, image, department, designation, joiningDate, salary]);

    const academic_divisions_list = ['Business Administration Department', 'Diagnostic Imaging Program', 'English Studies', 'Finance & Investment Department', 'General Education Program', 'Marketing Management Department', 'Research & Development', 'Library'];

    const designations_list = {
        'Business Administration Department': ['Dean of Business Studies', 'Dean of Students', 'Professors', 'Associate Professor', 'Assistant Professor', 'Instructor', 'Teaching assistant'],
        'Diagnostic Imaging Program': ['Director (DI Program)', 'Advanced DI Program Asst. Prof', 'Asst. Professors', 'DI Program Co-Ordinator', 'Instructor', 'Director (DI Lab & associate Professor)', 'DI Lab Assistants'],
        'English Studies': ['Chairman', 'Associate Professor'],
        'Finance & Investment Department': ['Chairman', 'Professor', 'Associate Professor'],
        'General Education Program': ['Director', 'Professors', 'Assistant Professors'],
        'Marketing Management Department': ['Chairman', 'Associate Professor'],
        'Research & Development': ['Institute Research Analyst'],
        'Library': ['Head', 'Library Assistant'],
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        const fSize = Math.round(files[0].size / 1024)
        const maxSize = 3072;// Allowing file type
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)/
        if (!allowedExtensions.exec(files[0]) && fSize < maxSize) {
            set_image(files[0]);
        } else {
            notification.open({
                message: 'Not Allowed',
                description: 'Please check image File format must be .jpg or .png and Max. file size 2 Mb',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    };

    const handleRemoveImage = () => {
        set_image('');
    };

    const onacademicDivChange = (value) => {
        set_academic_division(value);
        set_designation(designations_list[value]);
        set_second_designation(designations_list[value][0]);
    };

    const ADD_API = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('name', name);
        FORM_DATA.append('phone', phone);
        FORM_DATA.append('email', email);
        // FORM_DATA.append('password', password);
        // FORM_DATA.append('password_confirmation', password_confirmation);
        FORM_DATA.append('image', image);
        FORM_DATA.append('address', address);
        FORM_DATA.append('department', department);
        FORM_DATA.append('designation', second_designation);
        FORM_DATA.append('joiningDate', joiningDate);
        FORM_DATA.append('salary', salary);

        const API_RESPONSE = await ADD_FACULTIES(FORM_DATA);

        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Faculty Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/FacultiesList');
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false);
        }
    };

    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Add Faculty Page mn nmjbjn</h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                <div className='row'>
                    <div className='col-12'>
                        {/* Name */}
                        <div className='input-box'>
                            <label htmlFor="name">Name<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Name" id='name' onChange={(e) => set_name(e.target.value)} />
                            {errors?.name && <><span style={{ color: "red" }}>{errors?.name[0]}</span></>}
                        </div>
                        {/* Phone Number */}
                        <div className='input-box'>
                            <label htmlFor="phone">Phone Number<i style={{ color: "red" }}>*</i></label>
                            <Input maxLength={12} type='number'
                                keypress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                placeholder="Phone Number (max length 10 & 12)" id='phone' onChange={(e) => set_phone(e.target.value)} />
                            {errors?.phone && <><span style={{ color: "red" }}>{errors?.phone[0]}</span></>}
                        </div>

                        {/* Email */}
                        <div className='input-box'>
                            <label htmlFor="email">Email<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Email" id='email' onChange={(e) => set_email(e.target.value)} />
                            {errors?.email && <><span style={{ color: "red" }}>{errors?.email[0]}</span></>}
                        </div>

                        {/* Select Academic Division */}
                        <div className='input-box'>
                            <label htmlFor="Select-Academic-Division">Select Academic Division<i style={{ color: "red" }}>*</i></label><br></br>
                            <Select
                                placeholder="Select Academic Division"
                                // value={department}
                                onChange={onacademicDivChange}
                                style={{ width: '100%' }}
                                options={academic_divisions_list.map((item) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />
                            {errors?.department && <><span style={{ color: "red" }}>{errors?.department[0]}</span></>}
                        </div>
                        {/* Select Designation */}
                        <div className='input-box'>
                            <label htmlFor="Select-Designation">Select Designation</label><br></br>
                            <Select
                                placeholder="Select Designation"
                                // value={second_designation}
                                onChange={(value) => set_second_designation(value)}
                                style={{ width: '100%' }}
                                options={designation.map((item) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />
                            {errors?.category && <><span style={{ color: "red" }}>{errors?.category[0]}</span></>}
                        </div>
                        {/* Password */}
                        {/* <div className='input-box'>
                            <label htmlFor="Password">Password<i style={{ color: "red" }}>*</i></label>
                            <Input.Password placeholder="input password" autoComplete="new-password" onChange={(e) => set_password(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            {errors?.password && <><span style={{ color: "red" }}>{errors?.password[0]}</span></>}
                        </div> */}

                        {/* Confirm Password */}
                        {/* <div className='input-box'>
                            <label htmlFor="Password">Password Confirmation<i style={{ color: "red" }}>*</i></label>
                            <Input.Password placeholder="input password" autoComplete="new-password" onChange={(e) => set_password_confirmation(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            {errors?.password_confirmation && <><span style={{ color: "red" }}>{errors?.password_confirmation[0]}</span></>}
                        </div> */}

                        {/* Address */}
                        <div className='input-box'>
                            <label htmlFor="address">Address</label>
                            <Input placeholder="Place Enter Your address" id='address' onChange={(e) => set_address(e.target.value)} />
                            {errors?.address && <><span style={{ color: "red" }}>{errors?.address[0]}</span></>}
                        </div>

                        {/* joiningDate */}
{/* 
                        <div className='input-box'>
                            <label htmlFor="name">Joining Date<i style={{ color: "red" }}>*</i></label>
                            <DatePicker
                                // format="YYYY-MM-DD"
                                onChange={(date, dateString) => set_joiningDate(dateString)} style={{ width: "100%" }} />
                            {errors?.joiningDate && <><span style={{ color: "red" }}>{errors?.joiningDate[0]}</span></>}
                        </div> */}

                        {/* salary */}
                        {/* <div className='input-box'>
                            <label htmlFor='salary'>Salary</label>
                            <Input
                                placeholder='Place Enter Your Salary'
                                type='number' id='salary'
                                onChange={(e) => set_salary(e.target.value)} />
                            {errors?.salary && <><span style={{ color: 'red' }}>{errors?.salary[0]}</span> </>}

                        </div> */}

                        {/* Image */}
                        <div className='input-box' style={{ position: "relative" }}>
                            <label htmlFor="image">faculty image</label><br></br>
                            <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label>
                            {image &&
                                <>
                                    <div className="image-box" style={{ backgroundImage: "url(" + URL.createObjectURL(image) + ")" }}></div>
                                    <button className='image-remove' onClick={() => handleRemoveImage()}><CloseOutlined /></button>
                                </>
                            }
                            {errors?.image && <><span style={{ color: "red" }}>{errors?.image[0]}</span></>}

                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Add Faculty</Button>
                            </> : <>
                                <Button type="primary" onClick={ADD_API}>Add Faculty</Button>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFaculty;
import React, { useEffect, useState } from 'react';
import { FACULTY_MODULES_CALENDER_LIST_API } from '../../apis/apis'
import SisLoader from '../../widgets/loader';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const FacultyRecordAttendanceClassTimingList = ({class_date}) => {

    const navigate = useNavigate();
    
    const [loader, set_loader] = useState(true);
    const [table_list, set_table_list] = useState([]);
    const FACULTY_CLASS_API = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("class_date", class_date);
        FORM_DATA.append('faculty', USER_DATA.id);
        // FORM_DATA.append("class_time", );
        const API_RESPONSE = await FACULTY_MODULES_CALENDER_LIST_API(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE?.data?.list)
            set_loader(false);
        } else {
            set_loader(false);
        }
    }

    useEffect(() => {
        FACULTY_CLASS_API();
    }, [class_date]);

    const compareTimes = (time1, time2) => {
        if (time1 >= time2 && time1 <= time2) {
            return 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <ul>
                {loader ? <SisLoader /> : <>
                    {table_list.length > 0 ? <>
                        {table_list.map((item) => (
                            <>
                              <li
                            className='classlist-new' key={item.print_time}><span>{item.print_time}</span>
                                {/* {compareTimes(item.time_24, item.time_24) > 0 && } */}
                                {item?.course_list?.length > 0 ? <> 
                                {item?.course_list?.map((course_class) => (
                                    <>
                                     <div className='row' style={{ maxWidth: "900px", margin: "0 auto", backgroundColor: "rgb(255, 242, 224)", marginBottom: "8px", padding: "10px 0px", borderRadius: "5px" }}>
                                    <div onClick={() => navigate('/faculty-record-attendance-list/' + btoa(course_class?.faculty?.id) + '/' + btoa(course_class?.course?.id) + '/' + btoa(course_class?.course?.code) + '/' + btoa(course_class?.course?.title) + '/' + btoa(course_class?.semester_id) + '/' + btoa(course_class?.time) + '/' + btoa(course_class?.start_time) + '/' + btoa(course_class?.end_time) + '/' + btoa(course_class?.faculty?.name) + '/' + btoa(course_class?.faculty?.honorific) + '/' + btoa(class_date))} className='col-4' style={{ cursor: "pointer" }}>{course_class?.course?.title}</div>
                                    <div className='col-2'>{course_class?.course?.code}</div>
                                    <div className='col-3'>{course_class?.time}</div>
                                    <div className='col-2' style={{ textTransform: 'capitalize' }}>{course_class?.faculty?.honorific} {course_class?.faculty?.name}</div>
                                    <div className='col-1'><span style={{ backgroundColor: "#FFAE42", padding: "2px 10px", borderRadius: "5px" }}><UserOutlined /> {course_class?.students}</span></div>
                                </div>
                                    </>
                                ))}
                                </> : <>
                                <p style={{textAlign:"center", color:"red"}}>No Class Schedule</p>
                                </>}
                               
                                </li>
                            </>
                        ))}
                    </> : <>
                        
                    </>}

                </>}
            </ul>
        </>
    );
}

export default  FacultyRecordAttendanceClassTimingList;
